{
	"v": "4.8.0",
	"meta": { "g": "LottieFiles AE 3.5.9", "a": "", "k": "", "d": "", "tc": "" },
	"fr": 24,
	"ip": 0,
	"op": 48,
	"w": 100,
	"h": 50,
	"nm": "Comp 3",
	"ddd": 0,
	"assets": [
		{
			"id": "comp_0",
			"layers": [
				{
					"ddd": 0,
					"ind": 1,
					"ty": 0,
					"nm": "Shape Layer 3 Comp 1",
					"refId": "comp_1",
					"sr": 1,
					"ks": {
						"o": { "a": 0, "k": 100, "ix": 11 },
						"r": { "a": 0, "k": 0, "ix": 10 },
						"p": { "a": 0, "k": [1747, 646, 0], "ix": 2 },
						"a": { "a": 0, "k": [1280, 832, 0], "ix": 1 },
						"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
					},
					"ao": 0,
					"w": 2560,
					"h": 1664,
					"ip": 0,
					"op": 1320,
					"st": 0,
					"bm": 0
				},
				{
					"ddd": 0,
					"ind": 2,
					"ty": 0,
					"nm": "Shape Layer 3 Comp 1",
					"refId": "comp_1",
					"sr": 1,
					"ks": {
						"o": { "a": 0, "k": 100, "ix": 11 },
						"r": { "a": 0, "k": 0, "ix": 10 },
						"p": { "a": 0, "k": [1722, 646, 0], "ix": 2 },
						"a": { "a": 0, "k": [1280, 832, 0], "ix": 1 },
						"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
					},
					"ao": 0,
					"w": 2560,
					"h": 1664,
					"ip": -2,
					"op": 1318,
					"st": -2,
					"bm": 0
				},
				{
					"ddd": 0,
					"ind": 3,
					"ty": 0,
					"nm": "Shape Layer 3 Comp 1",
					"refId": "comp_1",
					"sr": 1,
					"ks": {
						"o": { "a": 0, "k": 100, "ix": 11 },
						"r": { "a": 0, "k": 0, "ix": 10 },
						"p": { "a": 0, "k": [1697, 646, 0], "ix": 2 },
						"a": { "a": 0, "k": [1280, 832, 0], "ix": 1 },
						"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
					},
					"ao": 0,
					"w": 2560,
					"h": 1664,
					"ip": -4,
					"op": 1316,
					"st": -4,
					"bm": 0
				}
			]
		},
		{
			"id": "comp_1",
			"layers": [
				{
					"ddd": 0,
					"ind": 1,
					"ty": 4,
					"nm": "Shape Layer 3",
					"sr": 1,
					"ks": {
						"o": {
							"a": 1,
							"k": [
								{
									"i": { "x": [0.11], "y": [1] },
									"o": { "x": [0.89], "y": [0] },
									"t": 17,
									"s": [50]
								},
								{
									"i": { "x": [0.11], "y": [1] },
									"o": { "x": [0.89], "y": [0] },
									"t": 26,
									"s": [100]
								},
								{ "t": 46, "s": [50] }
							],
							"ix": 11
						},
						"r": { "a": 0, "k": 0, "ix": 10 },
						"p": {
							"a": 1,
							"k": [
								{
									"i": { "x": 0.33, "y": 1 },
									"o": { "x": 0.167, "y": 0.189 },
									"t": 22,
									"s": [910.671, 1390.671, 0],
									"to": [0, 0, 0],
									"ti": [0, 0, 0]
								},
								{
									"i": { "x": 0.33, "y": 1 },
									"o": { "x": 0.167, "y": 0.115 },
									"t": 29,
									"s": [915.171, 1377.171, 0],
									"to": [0, 0, 0],
									"ti": [2.671, 7.546, 0]
								},
								{
									"i": { "x": 0.33, "y": 1 },
									"o": { "x": 0.167, "y": 0.653 },
									"t": 34,
									"s": [915.171, 1398.171, 0],
									"to": [-2.671, -7.546, 0],
									"ti": [0.75, 1.25, 0]
								},
								{ "t": 46, "s": [910.671, 1390.671, 0] }
							],
							"ix": 2
						},
						"a": { "a": 0, "k": [-367.329, 558.671, 0], "ix": 1 },
						"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
					},
					"ao": 0,
					"shapes": [
						{
							"ty": "gr",
							"it": [
								{
									"d": 1,
									"ty": "el",
									"s": { "a": 0, "k": [15.342, 15.342], "ix": 2 },
									"p": { "a": 0, "k": [0, 0], "ix": 3 },
									"nm": "Ellipse Path 1",
									"mn": "ADBE Vector Shape - Ellipse",
									"hd": false
								},
								{
									"ty": "fl",
									"c": {
										"a": 0,
										"k": [0.003921568627, 0.262745098039, 0.325490196078, 1],
										"ix": 4
									},
									"o": { "a": 0, "k": 100, "ix": 5 },
									"r": 1,
									"bm": 0,
									"nm": "Fill 1",
									"mn": "ADBE Vector Graphic - Fill",
									"hd": false
								},
								{
									"ty": "tr",
									"p": { "a": 0, "k": [-367.329, 558.671], "ix": 2 },
									"a": { "a": 0, "k": [0, 0], "ix": 1 },
									"s": { "a": 0, "k": [100, 100], "ix": 3 },
									"r": { "a": 0, "k": 0, "ix": 6 },
									"o": { "a": 0, "k": 100, "ix": 7 },
									"sk": { "a": 0, "k": 0, "ix": 4 },
									"sa": { "a": 0, "k": 0, "ix": 5 },
									"nm": "Transform"
								}
							],
							"nm": "Ellipse 1",
							"np": 3,
							"cix": 2,
							"bm": 0,
							"ix": 1,
							"mn": "ADBE Vector Group",
							"hd": false
						}
					],
					"ip": 0,
					"op": 1320,
					"st": 0,
					"bm": 0
				}
			]
		}
	],
	"layers": [
		{
			"ddd": 0,
			"ind": 1,
			"ty": 0,
			"nm": "dots",
			"refId": "comp_0",
			"sr": 1,
			"ks": {
				"o": { "a": 0, "k": 100, "ix": 11 },
				"r": { "a": 0, "k": 0, "ix": 10 },
				"p": { "a": 0, "k": [-23, -347, 0], "ix": 2 },
				"a": { "a": 0, "k": [1280, 832, 0], "ix": 1 },
				"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
			},
			"ao": 0,
			"w": 2560,
			"h": 1664,
			"ip": 0,
			"op": 2760,
			"st": 0,
			"bm": 0
		}
	],
	"markers": []
}
