import { useLottie } from "lottie-react";

import typingAnimation from "./typing.lottie.json";
import styles from "./Typing.module.css";

export function TypingIndicatorInner() {
  const { View } = useLottie({ animationData: typingAnimation, loop: true });
  return View;
}

export default function TypingIndicator() {
  return (
    <div className={styles[`typing-indicator`]}>
			<TypingIndicatorInner />
		</div>);

}